/* Theme colours - NOTE - not supported in IE11*/
:root {
  --flg-offWhite: #f3f2f4;
  --flg-lightGrey: #e8e5e9;
  --flg-focusOrange: #f6c15e;
  --flg-purple: #705ce4;
}

body.v3 {
  margin: 0px;
  padding: 0px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
  overflow-x: hidden;
  line-height: 1.5;
  width: 100vw;
  height: 100vh;
}

.v3 * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: box-shadow 150ms;
}

/* This will only work in Chrome but will do no harm elsewhere: */
.v3 ::-webkit-scrollbar { 
  width: 8px; 
  height: 8px; 
}

.v3 ::-webkit-scrollbar-track,
.v3 ::-webkit-scrollbar-corner {
  background: #f3f2f4;
  background: var(--flg-offWhite);
}

.v3 ::-webkit-scrollbar-thumb {
  background: #c5c0c9;
  border-radius: 4px;
}

.v3 ::-webkit-scrollbar-thumb:hover {
  background: #8D8194;
}

.v3 ::-webkit-scrollbar-button { 
  display: none; 
}

.v3 #root {
  height: 100%;
  width: 100vw;
}

.v3 a {
  color: #705ce4;
  color: var(--flg-purple);
  outline-color: #f6c15e;
  outline-color: var(--flg-focusOrange);
}

.v3 a:hover {
  text-decoration: none;
}

.v3.loading,
.v3 .loading {
  position: relative;
  display: block !important;
}

.v3.loading > *,
.v3 .loading > * {
  display: none !important;
}

.v3.loading:before,
.v3 .loading:before {
  display: block;
  margin: auto;
  padding: 0;
  content: ' ';
  width: 100%;
  height: 100%;
  max-width: 5rem;
  pointer-events: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg aria-hidden="true" data-prefix="far" data-icon="spinner-third" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-spinner-third fa-w-16 fa-3x"><path fill="%23c5c0c9" d="M460.116 373.846l-20.823-12.022c-5.541-3.199-7.54-10.159-4.663-15.874 30.137-59.886 28.343-131.652-5.386-189.946-33.641-58.394-94.896-95.833-161.827-99.676C261.028 55.961 256 50.751 256 44.352V20.309c0-6.904 5.808-12.337 12.703-11.982 83.556 4.306 160.163 50.864 202.11 123.677 42.063 72.696 44.079 162.316 6.031 236.832-3.14 6.148-10.75 8.461-16.728 5.01z" class=""></path></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  animation-name: rotating;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 1s;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.v3 .fill-parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.relative-to-sidebar .fill-parent .loading {
  left: 230px;
}

.v3 .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 0;
  transition: background 300ms;
}

.v3 .modal-overlay:before {
  background: #1C042A;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 0;
  transition: opacity 300ms;
  content: ' ';
}

.v3 .modal-overlay.ReactModal__Overlay--after-open:not(.ReactModal__Overlay--before-close):before {
  opacity: 0.5;
}

.v3 .hidden-modal-overlay:before { 
  opacity: 0; 
}

/**
 * Tooltip Styles
 */

/* Add this attribute to the element that needs a tooltip */
[data-tooltip] {
  position: relative;
  z-index: 15;
}

/* Hide the tooltip content by default */
[data-tooltip]::before,
[data-tooltip]::after {
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  pointer-events: none;
}

/* Position tooltip above the element */
[data-tooltip]::before {
  position: absolute;
  top: 120%;
  left: 50%;
  margin-bottom: 5px;
  padding: 6px 13px;
  border-radius: 3px;
  background-color: var(--flg-purple);
  color: #fff;
  content: attr(data-tooltip);
  font-weight: normal;
  text-align: center;
  font-size: 16px;
  line-height: 1.2;
  white-space: nowrap;
}

/* Show tooltip content on hover */
[data-tooltip]:hover::before,
[data-tooltip]:hover::after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

/* Left */
.tooltip-left::before {
  top: 50%;
  right: 120%;
  left: auto;
  transform: translateY(-50%);
}
